


















































































































































































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mapGetters, mapMutations } from '@/store/utils'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  setup(_props, ctx) {
    const input = ref<HTMLInputElement>(null)
    const { tags, query } = mapGetters(ctx, 'searchtool', ['tags', 'query'])
    const { clearAll, storeAddTags, storeRemoveTag } = mapMutations(
      ctx,
      'searchtool',
      {
        clearAll: 'CLEAR_ALL',
        storeAddTags: 'ADD_TAGS',
        storeRemoveTag: 'REMOVE_TAG',
      }
    )

    const state = reactive({
      items: [],
    })

    function clear() {
      if (input.value) {
        input.value.value = ''
      }
    }

    function autocomplete(v: string) {
      if (v.length >= 3) {
        fetch(`${getApiUrl()}/search-autocomplete?s=${v}`)
          .then(res => res.json())
          .then(data => {
            state.items = data
          })
      } else {
        state.items = []
      }
    }

    function clearAutocomplete() {
      clear()
      state.items = []
    }

    function isSelected(tag: string) {
      return tags.value.selected.indexOf(tag) !== -1
    }

    function onSubmit() {
      if (input.value?.value === '') {
        return
      }

      if (input.value) {
        const tags = input.value.value.split(',')

        storeAddTags(tags)
      }

      clearAutocomplete()
      clear()

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    const removeTag = tag => {
      storeRemoveTag(tag)

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    const addTags = tags => {
      storeAddTags(tags)

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    const onClearAll = () => {
      clearAll()

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    return {
      addTags,
      autocomplete,
      onClearAll,
      clearAutocomplete,
      input,
      isSelected,
      onSubmit,
      removeTag,
      state,
      tags,
    }
  },
})
