


































import { computed, defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    const sentence = computed(() => {
      const tagsSelected = content.value.tags.selected

      const tags = `${tagsSelected
        .map(t => `<strong>"${decodeURI(t)}"</strong>`)
        .slice(0, 3)
        .join(', ')}${tagsSelected.length > 3 ? '...' : ''}`

      // Types
      const typesSelected: string[] = []

      Object.keys(content.value.filters.types).forEach(k => {
        if (content.value.filters.types[k].checked) {
          typesSelected.push(content.value.filters.types[k].label)
        }
      })

      const types = `${typesSelected
        .map(t => `<strong>${t}</strong>`)
        .slice(0, 3)
        .join(' & ')}${typesSelected.length > 3 ? '...' : ''}`

      // Topics
      const topicsSelected: string[] = []

      Object.keys(content.value.filters.topics).forEach(k => {
        if (content.value.filters.topics[k].checked) {
          topicsSelected.push(content.value.filters.topics[k].label)
        }
      })

      const topics = `${topicsSelected
        .map(t => `<strong>${t}</strong>`)
        .slice(0, 3)
        .join(' & ')}${topicsSelected.length > 3 ? '...' : ''}`

      // Regions
      const regionsSelected: string[] = []

      Object.keys(content.value.filters.regions).forEach(k => {
        if (content.value.filters.regions[k].checked) {
          regionsSelected.push(content.value.filters.regions[k].label)
        }
      })

      const regions = `${regionsSelected
        .map(r => `<strong>${r}</strong>`)
        .slice(0, 3)
        .join(' & ')}${regionsSelected.length > 3 ? '...' : ''}`

      // Sentence
      let sentence =
        typesSelected.length > 0
          ? `Here are the ${types}`
          : 'Here is the <strong>available content</strong>'

      sentence += topicsSelected.length > 0 ? `<br> on ${topics}` : ''
      sentence += regionsSelected.length > 0 ? `<br> in ${regions}` : ''
      sentence += tagsSelected.length > 0 ? `<br> with ${tags}` : ''

      return sentence
    })

    return {
      sentence,
    }
  },
})
