








































// Storybook.vue.ts
import { defineComponent, onMounted, ref } from '@vue/composition-api'
// Import axios from 'axios'
import CardSlider from '@/components/CardSlider.vue'
import GridCards from '@/components/CardGrid.vue'
// Import { fetch } from '@/router/cache'

export default defineComponent({
  name: 'storybook',
  components: {
    CardSlider,
    GridCards,
  },
  setup() {
    const items = ref(null)
    const packages = [
      {
        title: 'Early birds',
        items: [
          {
            label: 'My network',
            value: 'true',
          },
          {
            label: 'My Library',
            value: 'true',
          },
          {
            label: 'Events discounts',
            value: 'true',
          },
          {
            label: 'UITP publications',
            value: 'true',
          },
          {
            label: 'Third party prject participation',
            value: 'true',
          },
          {
            label: 'Boards & walking bodies',
            value: 'true',
          },
          {
            label: 'Expert support',
            value: 'false',
          },
          {
            label: 'Peer reviews',
            value: 'false',
          },
        ],
      },
      {
        title: 'Basic',
        items: [
          {
            label: 'My network',
            value: 'true',
          },
          {
            label: 'My Library',
            value: 'true',
          },
          {
            label: 'Events discounts',
            value: 'true',
          },
          {
            label: 'UITP publications',
            value: 'true',
          },
          {
            label: 'Third party prject participation',
            value: 'true',
          },
          {
            label: 'Boards & walking bodies',
            value: 'true',
          },
          {
            label: 'Expert support',
            value: 'true',
          },
          {
            label: 'Peer reviews',
            value: 'true',
          },
        ],
      },
      {
        title: 'Full Access',
        items: [
          {
            label: 'My network',
            value: 'true',
          },
          {
            label: 'My Library',
            value: 'true',
          },
          {
            label: 'Events discounts',
            value: 'true',
          },
          {
            label: 'UITP publications',
            value: 'true',
          },
          {
            label: 'Third party prject participation',
            value: 'true',
          },
          {
            label: 'Boards & walking bodies',
            value: 'true',
          },
          {
            label: 'Expert support',
            value: 'true',
          },
          {
            label: 'Peer reviews',
            value: 'true',
          },
        ],
      },
    ]
    onMounted(() => {
      fetch('/json/storybook.json')
        .then(response => response.json())
        .then(data => {
          items.value = data
        })
      // Axios
      //   .get('/json/storybook.json')
      //   .then(response => {
      //     items.value = response.data
      //     debugger
      //   }
      //   )
    })

    return {
      items: Object,
      packages,
    }
  },
})
