

















































































































































































































// GridCards.vue.ts
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Cardgrid',
  props: {
    content: Object,
  },
})
