













import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import CardGrid from '@/components/CardGrid.vue'
import CardSlider from '@/components/CardSlider.vue'

export default defineComponent({
  name: 'events',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const events = 'events'

    return {
      content,
      events,
    }
  },
  components: {
    CardGrid,
    CardSlider,
  },
})
