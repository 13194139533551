























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { parseRoute } from '@/inc/utils'
import { mapGetters } from '@/store/utils'
import SignatoriesGrid from '@/components/SignatoriesGrid.vue'
import axios from 'axios'

export default defineComponent({
  name: 'signatories',
  setup(_props, ctx) {
    const { $route } = ctx.root
    const { api } = parseRoute($route)
    const totalItems = ref()
    const category = $route.path.substring($route.path.lastIndexOf('/') + 1)

    const { content } = mapGetters(ctx, ['content'])

    onMounted(() => {
      axios.get(`${api}/signatories/${category}`).then(response => {
        console.log('🟢 data', response.data)

        totalItems.value = response.data
      })
    })

    return {
      category,
      content,
      totalItems,
    }
  },

  components: {
    SignatoriesGrid,
  },
})
