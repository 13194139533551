









































































































































































import { computed, defineComponent, ref } from '@vue/composition-api'

import { mapGetters, mapMutations } from '@/store/utils'
import FilterCheckbox from '@/components/searchtool/FilterCheckbox.vue'

export default defineComponent({
  props: {
    name: null,
  },
  setup(props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    const { clearFilter } = mapMutations(ctx, 'searchtool', {
      clearFilter: 'CLEAR_FILTER',
    })
    const isOpen = ref(false)

    const { query } = mapGetters(ctx, 'searchtool', ['query'])

    const checkboxes = computed(() =>
      Object.keys(content.value.filters[props.name]).map(k => ({
        name: k,
        group: props.name,
        ...content.value.filters[props.name][k],
      }))
    )

    function clear() {
      clearFilter(props.name)

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    function close() {
      isOpen.value = false
    }

    function toggle() {
      isOpen.value = !isOpen.value
    }

    return {
      checkboxes,
      clear,
      close,
      isOpen,
      toggle,
    }
  },
  components: {
    FilterCheckbox,
  },
})
