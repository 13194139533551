















































import { ref, onMounted, defineComponent } from '@vue/composition-api'
import CardSlider from '@/components/CardSlider.vue'
import HomeHighlight from '@/components/home/HomeHighlight.vue'
import MembersGrid from '@/components/members/Grid.vue'
import { mapGetters } from '@/store/utils'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'home',
  components: {
    CardSlider,
    HomeHighlight,
    MembersGrid,
  },
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const onboard = ref<HTMLElement | null>(null)
    const logo = ref<HTMLElement | null>(null)

    onMounted(() => {
      const width = onboard.value?.offsetWidth
      const height = onboard.value?.offsetHeight
      const clipProgress = {
        x: 0,
        y: height,
      }
      const tl = gsap.timeline()

      tl.add('onBoarding')
        .to(
          logo.value,
          {
            duration: 0.7,
            y: -50,
            opacity: 0,
            ease: 'power4.inOut',
          },
          '<+1'
        )
        .to(
          clipProgress,
          {
            duration: 1,
            x: width,
            y: 0,
            ease: 'power4.inOut',
            onUpdate: () => {
              if (onboard.value) {
                onboard.value.style.clip = `rect(0, ${width}px, ${height}px, ${clipProgress.x}px)`
              }
            },
          },
          '<+0.2'
        )
    })

    return {
      content,
      logo,
      onboard,
    }
  },
})
