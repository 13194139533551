










import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'contact',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    return {
      content,
    }
  },
})
