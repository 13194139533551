




















































import { computed, defineComponent, watch } from '@vue/composition-api'
import { push, GtmSearchClick } from '@/inc/utils'
import { mapGetters, mapMutations } from '@/store/utils' // eslint-disable-line

export default defineComponent({
  props: {
    type: {
      type: String,
      default: '',
    },
    title: String,
  },
  setup(props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const { query } = mapGetters(ctx, 'searchtool', ['query'])
    const { viewAll } = mapMutations(ctx, 'searchtool', {
      viewAll: 'VIEW_ALL',
    })

    const results = computed(() => content.value.results[props.type])
    const count = computed(() => results.value.length)

    const hasQuery = () => {
      const { query } = ctx.root.$route
      let r = false

      Object.keys(query).forEach(k => {
        if (query[k] !== '') {
          r = true
        }
      })

      return { r, query }
    }

    const sliceArray = (s, e, arr) => {
      const { length } = arr

      if (length === 0 || s > length) {
        return []
      }

      const end = e >= length ? length : e

      return arr.slice(s, end)
    }

    const highlights = computed(() => sliceArray(0, 1, results.value))
    const smalls = computed(() => sliceArray(1, 5, results.value))
    const mores = computed(() => sliceArray(5, count.value, results.value))
    const hasMore = computed(
      () => content.value.hasMore.indexOf(props.type) !== -1
    )

    const onClick = item => {
      if (ctx.root.$router.$$type === 'client') {
        const tags = query.value.tags as string
        const types = query.value.types as string
        const topics = query.value.topics as string
        const regions = query.value.regions as string

        /* eslint-disable quote-props */
        const layer: GtmSearchClick = {
          event: 'search-result-click',
          eventCategory: 'search-result',
          eventAction: 'click',
          eventLabel: item.url,
          search: {
            keywords: tags ? tags.split(',').join(' | ') : undefined,
            'search-type': types ? types.split(',').join(' | ') : 'all',
            'search-topic': topics ? topics.split(',').join(' | ') : 'all',
            'search-region': regions ? regions.split(',').join(' | ') : 'all',
            'search-flag': 1,
          },
        }
        /* eslint-enable quote-props */

        // DGTM 5.2.1
        push(layer)
      }
    }

    const onViewAll = () => {
      viewAll(props.type)

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    watch(() => {
      hasQuery()
    })

    return {
      count,
      hasMore,
      highlights,
      smalls,
      mores,
      results,
      onClick,
      onViewAll,
      query,
    }
  },
})
