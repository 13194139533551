var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-offices h-spacer-xl"},[_c('div',{staticClass:"about-offices-inner h-wrapper"},[_c('div',{staticClass:"about-offices__collapses"},[_c('about-collapse',{staticClass:"about-offices__collapse",attrs:{"active":_vm.active.slug,"open":true,"grid":true,"content":{
          title: 'Offices',
          groups: [
            {
              title: 'Head Office',
              items: _vm.offices.head,
            },
            {
              title: 'Liaison Offices',
              items: _vm.offices.liaison,
            },
            {
              title: 'Regional Offices',
              items: _vm.offices.regional,
            } ],
        },"onSelect":_vm.changeActive}}),_c('about-collapse',{staticClass:"about-offices__collapse",attrs:{"active":_vm.active.slug,"content":{
          title: 'Innovation',
          groups: [
            {
              title: 'Innovation Centers',
              items: _vm.offices.innov,
            } ],
        },"onSelect":_vm.changeActive}}),_c('about-collapse',{staticClass:"about-offices__collapse",attrs:{"active":_vm.active.slug,"content":{
          title: 'Training',
          groups: [
            {
              title: 'Training Centers',
              items: _vm.offices.training,
            } ],
        },"onSelect":_vm.changeActive}})],1),_c('about-map',{staticClass:"about-offices__map",attrs:{"selection":_vm.active,"points":_vm.points}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }