










import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

import CardSlider from '@/components/CardSlider.vue'

export default defineComponent({
  name: 'academy-about',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    return {
      content,
    }
  },

  components: {
    CardSlider,
  },
})
