




















import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import AboutOffices from '@/components/about/Offices.vue'

export default defineComponent({
  name: 'about',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    return {
      content,
    }
  },
  components: {
    AboutOffices,
  },
})
