



























































import { defineComponent } from '@vue/composition-api'
import { mapGetters, mapMutations } from '@/store/utils' // eslint-disable-line

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: {},
    },
  },
  setup(props, ctx) {
    const { query } = mapGetters(ctx, 'searchtool', ['query'])

    const { changeFilter } = mapMutations(ctx, 'searchtool', {
      changeFilter: 'CHANGE_FILTER',
    })

    const onClick = () => {
      changeFilter({
        name: props.content.name,
        group: props.content.group,
        checked: !props.content.checked,
      })

      ctx.root.$router.push({
        name: 'searchtool',
        query: query.value,
      })
    }

    return {
      onClick,
    }
  },
})
