




















































/* eslint-disable quote-props */

import Filters from '@/components/searchtool/Filters.vue'
import Keywords from '@/components/searchtool/Keywords.vue'
import Sentence from '@/components/searchtool/Sentence.vue'
import Results from '@/components/searchtool/Results.vue'

import { mapActions, mapGetters } from '@/store/utils'
import { push, GtmSearch } from '@/inc/utils'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'searchtool',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const { initSearchtool } = mapActions(ctx, 'searchtool', ['initSearchtool'])
    const watchCount = ref(0)
    const wrongLocation = [
      {
        title: content.value.wrongLocation?.title,
        link: content.value.wrongLocation?.link,
      },
    ]

    const hasResults = results => {
      const { events, medias, resources } = results

      return events.length + medias.length + resources.length > 0 ? 1 : 0
    }

    const hasQuery = () => {
      const { query } = ctx.root.$route
      let r = false

      Object.keys(query).forEach(k => {
        if (query[k] !== '') {
          r = true
        }
      })

      return r
    }

    const sendGTM = (query, results, initial) => {
      if (ctx.root.$router.$$type === 'client') {
        const tags = query.tags as string
        const types = query.types as string
        const topics = query.topics as string
        const regions = query.regions as string

        if (
          !initial &&
          tags === '' &&
          types === '' &&
          topics === '' &&
          regions === ''
        ) {
          return
        }

        const layer: GtmSearch = {
          event: initial ? 'virtualPage' : 'search',
          page: {
            language: 'en',
            category: initial ? 'search-open' : 'search-result',
          },
        }

        if (!initial) {
          layer.search = {
            keywords: tags ? tags.split(',').join(' | ') : undefined,
            'search-type': types ? types.split(',').join(' | ') : 'all',
            'search-topic': topics ? topics.split(',').join(' | ') : 'all',
            'search-region': regions ? regions.split(',').join(' | ') : 'all',
            'search-flag': hasResults(results),
          }
        }

        // DGTM 5.1.3
        push(layer)
      }
    }

    onMounted(() => {
      initSearchtool(content.value)
    })

    watch(
      () => content.value.results,
      () => {
        watchCount.value += 1

        const initialSearch = watchCount.value === 1 && !hasQuery()

        sendGTM(ctx.root.$route.query, content.value.results, initialSearch)
      }
    )

    return {
      content,
      wrongLocation,
    }
  },
  components: {
    Filters,
    Keywords,
    Sentence,
    Results,
  },
})
