


































































import {
  defineComponent,
  computed,
  reactive,
  onMounted,
} from '@vue/composition-api'
import AboutMap from '@/components/about/Map.vue'
import AboutCollapse from '@/components/about/Collapse.vue'

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AboutCollapse,
    AboutMap,
  },
  setup(props) {
    const active = reactive({
      address: '',
      email: '',
      label: '',
      city: '',
      slug: '',
      x: null,
      y: null,
    })
    const points = computed(() => props.content.items.map(i => [i.x, i.y]))
    const offices = reactive({
      head: props.content.items.filter(f => f.type === 'head-office'),
      regional: props.content.items.filter(f => f.type === 'regional-office'),
      liaison: props.content.items.filter(f => f.type === 'liaison-office'),
      innov: props.content.items.filter(f => f.type === 'innovation-center'),
      training: props.content.items.filter(f => f.type === 'training-center'),
    })
    const changeActive = item => {
      Object.assign(active, item)
    }

    onMounted(() => {
      Object.assign(active, offices.head[0])
    })

    return {
      offices,
      changeActive,
      active,
      points,
    }
  },
})
