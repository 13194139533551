



































































import { defineComponent, computed } from '@vue/composition-api'
import circles from './circles'

export default defineComponent({
  props: {
    selection: {
      type: Object,
      default: () => ({ x: 2, y: 10 }),
    },
    points: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const panePosition = computed(() => {
      const { selection } = props
      const xRatio = 100 / 57
      const yRatio = 100 / 35
      const xPerc = selection.x * xRatio
      const yPerc = selection.y * yRatio
      const offset = 20

      return {
        top:
          yPerc < 50
            ? `calc(${yPerc}% - ${offset}px)`
            : `calc(${yPerc}% - 340px + ${offset}px)`,
        left:
          xPerc < 50
            ? `calc(${xPerc}% + ${offset}px)`
            : `calc(${xPerc}% - 340px - ${offset}px)`,
      }
    })

    return {
      circles,
      panePosition,
    }
  },
})
