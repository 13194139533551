






import Searchtool from '@/components/searchtool/Searchtool.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'search',
  components: {
    Searchtool,
  },
})
