











































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import CardGrid from '@/components/CardGrid.vue'
// Import CardSlider from '@/components/CardSlider.vue'

export default defineComponent({
  name: 'media-center',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    const { path } = ctx.root.$route
    const isEvents = path.includes('events')
    const isTrainings = path.includes('trainings')

    return {
      content,
      isEvents,
      isTrainings,
    }
  },
  components: {
    CardGrid,
    // CardSlider,
  },
})
