export default [
  { cx: 1, cy: 9 },
  { cx: 2, cy: 7 },
  { cx: 2, cy: 8 },
  { cx: 2, cy: 9 },
  { cx: 2, cy: 10 },
  { cx: 2, cy: 11 },
  { cx: 2, cy: 12 },
  { cx: 3, cy: 7 },
  { cx: 3, cy: 8 },
  { cx: 3, cy: 9 },
  { cx: 3, cy: 10 },
  { cx: 3, cy: 11 },
  { cx: 4, cy: 7 },
  { cx: 4, cy: 8 },
  { cx: 4, cy: 9 },
  { cx: 4, cy: 10 },
  { cx: 4, cy: 11 },
  { cx: 5, cy: 8 },
  { cx: 5, cy: 9 },
  { cx: 5, cy: 10 },
  { cx: 5, cy: 11 },
  { cx: 5, cy: 12 },
  { cx: 6, cy: 8 },
  { cx: 6, cy: 9 },
  { cx: 6, cy: 10 },
  { cx: 6, cy: 11 },
  { cx: 6, cy: 12 },
  { cx: 6, cy: 13 },
  { cx: 7, cy: 7 },
  { cx: 7, cy: 8 },
  { cx: 7, cy: 9 },
  { cx: 7, cy: 10 },
  { cx: 7, cy: 11 },
  { cx: 7, cy: 12 },
  { cx: 7, cy: 13 },
  { cx: 7, cy: 14 },
  { cx: 8, cy: 5 },
  { cx: 8, cy: 6 },
  { cx: 8, cy: 8 },
  { cx: 8, cy: 9 },
  { cx: 8, cy: 10 },
  { cx: 8, cy: 11 },
  { cx: 8, cy: 12 },
  { cx: 8, cy: 13 },
  { cx: 8, cy: 14 },
  { cx: 8, cy: 15 },
  { cx: 8, cy: 16 },
  { cx: 8, cy: 17 },
  { cx: 9, cy: 5 },
  { cx: 9, cy: 6 },
  { cx: 9, cy: 7 },
  { cx: 9, cy: 8 },
  { cx: 9, cy: 9 },
  { cx: 9, cy: 10 },
  { cx: 9, cy: 11 },
  { cx: 9, cy: 12 },
  { cx: 9, cy: 13 },
  { cx: 9, cy: 14 },
  { cx: 9, cy: 15 },
  { cx: 9, cy: 16 },
  { cx: 9, cy: 17 },
  { cx: 9, cy: 18 },
  { cx: 10, cy: 5 },
  { cx: 10, cy: 6 },
  { cx: 10, cy: 7 },
  { cx: 10, cy: 8 },
  { cx: 10, cy: 9 },
  { cx: 10, cy: 10 },
  { cx: 10, cy: 11 },
  { cx: 10, cy: 12 },
  { cx: 10, cy: 13 },
  { cx: 10, cy: 14 },
  { cx: 10, cy: 15 },
  { cx: 10, cy: 16 },
  { cx: 10, cy: 17 },
  { cx: 10, cy: 18 },
  { cx: 10, cy: 19 },
  { cx: 11, cy: 5 },
  { cx: 11, cy: 6 },
  { cx: 11, cy: 7 },
  { cx: 11, cy: 8 },
  { cx: 11, cy: 9 },
  { cx: 11, cy: 10 },
  { cx: 11, cy: 11 },
  { cx: 11, cy: 12 },
  { cx: 11, cy: 13 },
  { cx: 11, cy: 14 },
  { cx: 11, cy: 15 },
  { cx: 11, cy: 16 },
  { cx: 11, cy: 17 },
  { cx: 11, cy: 18 },
  { cx: 11, cy: 19 },
  { cx: 11, cy: 20 },
  { cx: 12, cy: 4 },
  { cx: 12, cy: 5 },
  { cx: 12, cy: 6 },
  { cx: 12, cy: 7 },
  { cx: 12, cy: 8 },
  { cx: 12, cy: 9 },
  { cx: 12, cy: 10 },
  { cx: 12, cy: 11 },
  { cx: 12, cy: 13 },
  { cx: 12, cy: 14 },
  { cx: 12, cy: 15 },
  { cx: 12, cy: 16 },
  { cx: 12, cy: 17 },
  { cx: 12, cy: 20 },
  { cx: 13, cy: 3 },
  { cx: 13, cy: 4 },
  { cx: 13, cy: 5 },
  { cx: 13, cy: 6 },
  { cx: 13, cy: 7 },
  { cx: 13, cy: 8 },
  { cx: 13, cy: 9 },
  { cx: 13, cy: 14 },
  { cx: 13, cy: 15 },
  { cx: 13, cy: 16 },
  { cx: 13, cy: 17 },
  { cx: 13, cy: 20 },
  { cx: 13, cy: 21 },
  { cx: 14, cy: 3 },
  { cx: 14, cy: 4 },
  { cx: 14, cy: 5 },
  { cx: 14, cy: 6 },
  { cx: 14, cy: 8 },
  { cx: 14, cy: 9 },
  { cx: 14, cy: 10 },
  { cx: 14, cy: 11 },
  { cx: 14, cy: 12 },
  { cx: 14, cy: 13 },
  { cx: 14, cy: 14 },
  { cx: 14, cy: 15 },
  { cx: 14, cy: 16 },
  { cx: 14, cy: 17 },
  { cx: 14, cy: 18 },
  { cx: 14, cy: 22 },
  { cx: 14, cy: 24 },
  { cx: 14, cy: 25 },
  { cx: 15, cy: 9 },
  { cx: 15, cy: 10 },
  { cx: 15, cy: 11 },
  { cx: 15, cy: 12 },
  { cx: 15, cy: 13 },
  { cx: 15, cy: 14 },
  { cx: 15, cy: 15 },
  { cx: 15, cy: 19 },
  { cx: 15, cy: 22 },
  { cx: 15, cy: 23 },
  { cx: 15, cy: 24 },
  { cx: 15, cy: 25 },
  { cx: 15, cy: 26 },
  { cx: 15, cy: 30 },
  { cx: 15, cy: 31 },
  { cx: 15, cy: 32 },
  { cx: 15, cy: 33 },
  { cx: 16, cy: 4 },
  { cx: 16, cy: 10 },
  { cx: 16, cy: 11 },
  { cx: 16, cy: 12 },
  { cx: 16, cy: 13 },
  { cx: 16, cy: 14 },
  { cx: 16, cy: 15 },
  { cx: 16, cy: 22 },
  { cx: 16, cy: 23 },
  { cx: 16, cy: 24 },
  { cx: 16, cy: 25 },
  { cx: 16, cy: 26 },
  { cx: 16, cy: 27 },
  { cx: 16, cy: 28 },
  { cx: 16, cy: 29 },
  { cx: 16, cy: 30 },
  { cx: 16, cy: 31 },
  { cx: 16, cy: 32 },
  { cx: 16, cy: 33 },
  { cx: 16, cy: 34 },
  { cx: 17, cy: 3 },
  { cx: 17, cy: 4 },
  { cx: 17, cy: 5 },
  { cx: 17, cy: 12 },
  { cx: 17, cy: 22 },
  { cx: 17, cy: 23 },
  { cx: 17, cy: 24 },
  { cx: 17, cy: 25 },
  { cx: 17, cy: 26 },
  { cx: 17, cy: 27 },
  { cx: 17, cy: 28 },
  { cx: 17, cy: 29 },
  { cx: 17, cy: 30 },
  { cx: 18, cy: 2 },
  { cx: 18, cy: 3 },
  { cx: 18, cy: 4 },
  { cx: 18, cy: 5 },
  { cx: 18, cy: 6 },
  { cx: 18, cy: 7 },
  { cx: 18, cy: 22 },
  { cx: 18, cy: 23 },
  { cx: 18, cy: 24 },
  { cx: 18, cy: 25 },
  { cx: 18, cy: 26 },
  { cx: 18, cy: 27 },
  { cx: 18, cy: 28 },
  { cx: 18, cy: 29 },
  { cx: 19, cy: 1 },
  { cx: 19, cy: 2 },
  { cx: 19, cy: 3 },
  { cx: 19, cy: 4 },
  { cx: 19, cy: 5 },
  { cx: 19, cy: 6 },
  { cx: 19, cy: 7 },
  { cx: 19, cy: 8 },
  { cx: 19, cy: 9 },
  { cx: 19, cy: 10 },
  { cx: 19, cy: 23 },
  { cx: 19, cy: 24 },
  { cx: 19, cy: 25 },
  { cx: 19, cy: 26 },
  { cx: 19, cy: 27 },
  { cx: 19, cy: 28 },
  { cx: 20, cy: 1 },
  { cx: 20, cy: 2 },
  { cx: 20, cy: 3 },
  { cx: 20, cy: 4 },
  { cx: 20, cy: 5 },
  { cx: 20, cy: 6 },
  { cx: 20, cy: 7 },
  { cx: 20, cy: 8 },
  { cx: 20, cy: 9 },
  { cx: 20, cy: 24 },
  { cx: 20, cy: 25 },
  { cx: 20, cy: 26 },
  { cx: 20, cy: 27 },
  { cx: 21, cy: 1 },
  { cx: 21, cy: 2 },
  { cx: 21, cy: 3 },
  { cx: 21, cy: 4 },
  { cx: 21, cy: 5 },
  { cx: 21, cy: 6 },
  { cx: 21, cy: 7 },
  { cx: 21, cy: 8 },
  { cx: 21, cy: 24 },
  { cx: 21, cy: 25 },
  { cx: 22, cy: 2 },
  { cx: 22, cy: 3 },
  { cx: 22, cy: 4 },
  { cx: 22, cy: 5 },
  { cx: 22, cy: 6 },
  { cx: 22, cy: 7 },
  { cx: 22, cy: 8 },
  { cx: 23, cy: 4 },
  { cx: 23, cy: 5 },
  { cx: 24, cy: 10 },
  { cx: 24, cy: 20 },
  { cx: 24, cy: 21 },
  { cx: 24, cy: 22 },
  { cx: 25, cy: 10 },
  { cx: 25, cy: 15 },
  { cx: 25, cy: 16 },
  { cx: 25, cy: 19 },
  { cx: 25, cy: 20 },
  { cx: 25, cy: 21 },
  { cx: 25, cy: 22 },
  { cx: 25, cy: 23 },
  { cx: 26, cy: 12 },
  { cx: 26, cy: 13 },
  { cx: 26, cy: 15 },
  { cx: 26, cy: 16 },
  { cx: 26, cy: 18 },
  { cx: 26, cy: 19 },
  { cx: 26, cy: 20 },
  { cx: 26, cy: 21 },
  { cx: 26, cy: 22 },
  { cx: 26, cy: 23 },
  { cx: 27, cy: 12 },
  { cx: 27, cy: 13 },
  { cx: 27, cy: 14 },
  { cx: 27, cy: 15 },
  { cx: 27, cy: 18 },
  { cx: 27, cy: 19 },
  { cx: 27, cy: 20 },
  { cx: 27, cy: 21 },
  { cx: 27, cy: 22 },
  { cx: 27, cy: 23 },
  { cx: 28, cy: 10 },
  { cx: 28, cy: 11 },
  { cx: 28, cy: 13 },
  { cx: 28, cy: 14 },
  { cx: 28, cy: 15 },
  { cx: 28, cy: 18 },
  { cx: 28, cy: 19 },
  { cx: 28, cy: 20 },
  { cx: 28, cy: 21 },
  { cx: 28, cy: 22 },
  { cx: 28, cy: 23 },
  { cx: 28, cy: 24 },
  { cx: 28, cy: 25 },
  { cx: 29, cy: 9 },
  { cx: 29, cy: 10 },
  { cx: 29, cy: 11 },
  { cx: 29, cy: 12 },
  { cx: 29, cy: 13 },
  { cx: 29, cy: 14 },
  { cx: 29, cy: 15 },
  { cx: 29, cy: 16 },
  { cx: 29, cy: 19 },
  { cx: 29, cy: 20 },
  { cx: 29, cy: 21 },
  { cx: 29, cy: 22 },
  { cx: 29, cy: 23 },
  { cx: 29, cy: 24 },
  { cx: 29, cy: 25 },
  { cx: 29, cy: 26 },
  { cx: 29, cy: 27 },
  { cx: 29, cy: 28 },
  { cx: 29, cy: 29 },
  { cx: 29, cy: 30 },
  { cx: 30, cy: 8 },
  { cx: 30, cy: 9 },
  { cx: 30, cy: 10 },
  { cx: 30, cy: 11 },
  { cx: 30, cy: 13 },
  { cx: 30, cy: 14 },
  { cx: 30, cy: 15 },
  { cx: 30, cy: 18 },
  { cx: 30, cy: 19 },
  { cx: 30, cy: 20 },
  { cx: 30, cy: 21 },
  { cx: 30, cy: 22 },
  { cx: 30, cy: 23 },
  { cx: 30, cy: 24 },
  { cx: 30, cy: 25 },
  { cx: 30, cy: 26 },
  { cx: 30, cy: 27 },
  { cx: 30, cy: 28 },
  { cx: 30, cy: 29 },
  { cx: 30, cy: 30 },
  { cx: 31, cy: 5 },
  { cx: 31, cy: 7 },
  { cx: 31, cy: 8 },
  { cx: 31, cy: 9 },
  { cx: 31, cy: 12 },
  { cx: 31, cy: 13 },
  { cx: 31, cy: 14 },
  { cx: 31, cy: 15 },
  { cx: 31, cy: 16 },
  { cx: 31, cy: 17 },
  { cx: 31, cy: 19 },
  { cx: 31, cy: 20 },
  { cx: 31, cy: 21 },
  { cx: 31, cy: 22 },
  { cx: 31, cy: 23 },
  { cx: 31, cy: 24 },
  { cx: 31, cy: 25 },
  { cx: 31, cy: 26 },
  { cx: 31, cy: 27 },
  { cx: 31, cy: 28 },
  { cx: 31, cy: 29 },
  { cx: 32, cy: 7 },
  { cx: 32, cy: 8 },
  { cx: 32, cy: 9 },
  { cx: 32, cy: 10 },
  { cx: 32, cy: 11 },
  { cx: 32, cy: 12 },
  { cx: 32, cy: 13 },
  { cx: 32, cy: 14 },
  { cx: 32, cy: 15 },
  { cx: 32, cy: 16 },
  { cx: 32, cy: 18 },
  { cx: 32, cy: 19 },
  { cx: 32, cy: 20 },
  { cx: 32, cy: 21 },
  { cx: 32, cy: 22 },
  { cx: 32, cy: 23 },
  { cx: 32, cy: 24 },
  { cx: 32, cy: 25 },
  { cx: 32, cy: 26 },
  { cx: 32, cy: 27 },
  { cx: 33, cy: 8 },
  { cx: 33, cy: 9 },
  { cx: 33, cy: 10 },
  { cx: 33, cy: 11 },
  { cx: 33, cy: 12 },
  { cx: 33, cy: 13 },
  { cx: 33, cy: 14 },
  { cx: 33, cy: 15 },
  { cx: 33, cy: 17 },
  { cx: 33, cy: 18 },
  { cx: 33, cy: 19 },
  { cx: 33, cy: 21 },
  { cx: 33, cy: 22 },
  { cx: 33, cy: 23 },
  { cx: 33, cy: 24 },
  { cx: 33, cy: 28 },
  { cx: 33, cy: 29 },
  { cx: 34, cy: 9 },
  { cx: 34, cy: 10 },
  { cx: 34, cy: 11 },
  { cx: 34, cy: 12 },
  { cx: 34, cy: 13 },
  { cx: 34, cy: 14 },
  { cx: 34, cy: 15 },
  { cx: 34, cy: 16 },
  { cx: 34, cy: 17 },
  { cx: 34, cy: 18 },
  { cx: 34, cy: 19 },
  { cx: 34, cy: 20 },
  { cx: 34, cy: 22 },
  { cx: 34, cy: 23 },
  { cx: 35, cy: 7 },
  { cx: 35, cy: 9 },
  { cx: 35, cy: 10 },
  { cx: 35, cy: 11 },
  { cx: 35, cy: 12 },
  { cx: 35, cy: 13 },
  { cx: 35, cy: 14 },
  { cx: 35, cy: 15 },
  { cx: 35, cy: 16 },
  { cx: 35, cy: 18 },
  { cx: 35, cy: 19 },
  { cx: 35, cy: 20 },
  { cx: 35, cy: 21 },
  { cx: 36, cy: 6 },
  { cx: 36, cy: 8 },
  { cx: 36, cy: 9 },
  { cx: 36, cy: 10 },
  { cx: 36, cy: 11 },
  { cx: 36, cy: 12 },
  { cx: 36, cy: 13 },
  { cx: 36, cy: 14 },
  { cx: 36, cy: 16 },
  { cx: 36, cy: 17 },
  { cx: 36, cy: 19 },
  { cx: 36, cy: 20 },
  { cx: 37, cy: 6 },
  { cx: 37, cy: 9 },
  { cx: 37, cy: 10 },
  { cx: 37, cy: 11 },
  { cx: 37, cy: 12 },
  { cx: 37, cy: 13 },
  { cx: 37, cy: 14 },
  { cx: 37, cy: 15 },
  { cx: 37, cy: 16 },
  { cx: 37, cy: 17 },
  { cx: 37, cy: 18 },
  { cx: 37, cy: 19 },
  { cx: 38, cy: 8 },
  { cx: 38, cy: 9 },
  { cx: 38, cy: 10 },
  { cx: 38, cy: 11 },
  { cx: 38, cy: 12 },
  { cx: 38, cy: 13 },
  { cx: 38, cy: 14 },
  { cx: 38, cy: 15 },
  { cx: 38, cy: 16 },
  { cx: 38, cy: 17 },
  { cx: 38, cy: 18 },
  { cx: 39, cy: 6 },
  { cx: 39, cy: 7 },
  { cx: 39, cy: 8 },
  { cx: 39, cy: 9 },
  { cx: 39, cy: 10 },
  { cx: 39, cy: 11 },
  { cx: 39, cy: 12 },
  { cx: 39, cy: 13 },
  { cx: 39, cy: 14 },
  { cx: 39, cy: 15 },
  { cx: 39, cy: 16 },
  { cx: 39, cy: 17 },
  { cx: 39, cy: 18 },
  { cx: 39, cy: 19 },
  { cx: 40, cy: 6 },
  { cx: 40, cy: 7 },
  { cx: 40, cy: 8 },
  { cx: 40, cy: 9 },
  { cx: 40, cy: 10 },
  { cx: 40, cy: 11 },
  { cx: 40, cy: 12 },
  { cx: 40, cy: 13 },
  { cx: 40, cy: 14 },
  { cx: 40, cy: 15 },
  { cx: 40, cy: 16 },
  { cx: 40, cy: 17 },
  { cx: 40, cy: 18 },
  { cx: 40, cy: 19 },
  { cx: 40, cy: 20 },
  { cx: 41, cy: 6 },
  { cx: 41, cy: 7 },
  { cx: 41, cy: 8 },
  { cx: 41, cy: 9 },
  { cx: 41, cy: 10 },
  { cx: 41, cy: 11 },
  { cx: 41, cy: 12 },
  { cx: 41, cy: 13 },
  { cx: 41, cy: 14 },
  { cx: 41, cy: 15 },
  { cx: 41, cy: 16 },
  { cx: 41, cy: 17 },
  { cx: 41, cy: 18 },
  { cx: 41, cy: 19 },
  { cx: 42, cy: 5 },
  { cx: 42, cy: 6 },
  { cx: 42, cy: 7 },
  { cx: 42, cy: 8 },
  { cx: 42, cy: 9 },
  { cx: 42, cy: 10 },
  { cx: 42, cy: 11 },
  { cx: 42, cy: 12 },
  { cx: 42, cy: 13 },
  { cx: 42, cy: 14 },
  { cx: 42, cy: 15 },
  { cx: 42, cy: 16 },
  { cx: 42, cy: 17 },
  { cx: 42, cy: 18 },
  { cx: 43, cy: 4 },
  { cx: 43, cy: 5 },
  { cx: 43, cy: 6 },
  { cx: 43, cy: 7 },
  { cx: 43, cy: 8 },
  { cx: 43, cy: 9 },
  { cx: 43, cy: 10 },
  { cx: 43, cy: 11 },
  { cx: 43, cy: 12 },
  { cx: 43, cy: 13 },
  { cx: 43, cy: 14 },
  { cx: 43, cy: 15 },
  { cx: 43, cy: 16 },
  { cx: 43, cy: 17 },
  { cx: 43, cy: 18 },
  { cx: 44, cy: 3 },
  { cx: 44, cy: 4 },
  { cx: 44, cy: 5 },
  { cx: 44, cy: 6 },
  { cx: 44, cy: 7 },
  { cx: 44, cy: 8 },
  { cx: 44, cy: 9 },
  { cx: 44, cy: 10 },
  { cx: 44, cy: 11 },
  { cx: 44, cy: 12 },
  { cx: 44, cy: 13 },
  { cx: 44, cy: 14 },
  { cx: 44, cy: 15 },
  { cx: 44, cy: 16 },
  { cx: 44, cy: 17 },
  { cx: 44, cy: 18 },
  { cx: 44, cy: 19 },
  { cx: 44, cy: 20 },
  { cx: 44, cy: 21 },
  { cx: 45, cy: 4 },
  { cx: 45, cy: 5 },
  { cx: 45, cy: 6 },
  { cx: 45, cy: 7 },
  { cx: 45, cy: 8 },
  { cx: 45, cy: 9 },
  { cx: 45, cy: 10 },
  { cx: 45, cy: 11 },
  { cx: 45, cy: 12 },
  { cx: 45, cy: 13 },
  { cx: 45, cy: 14 },
  { cx: 45, cy: 15 },
  { cx: 45, cy: 16 },
  { cx: 45, cy: 17 },
  { cx: 45, cy: 18 },
  { cx: 45, cy: 19 },
  { cx: 45, cy: 20 },
  { cx: 45, cy: 21 },
  { cx: 45, cy: 22 },
  { cx: 45, cy: 23 },
  { cx: 46, cy: 6 },
  { cx: 46, cy: 7 },
  { cx: 46, cy: 8 },
  { cx: 46, cy: 9 },
  { cx: 46, cy: 10 },
  { cx: 46, cy: 11 },
  { cx: 46, cy: 12 },
  { cx: 46, cy: 13 },
  { cx: 46, cy: 14 },
  { cx: 46, cy: 15 },
  { cx: 46, cy: 16 },
  { cx: 46, cy: 17 },
  { cx: 46, cy: 18 },
  { cx: 46, cy: 19 },
  { cx: 46, cy: 23 },
  { cx: 46, cy: 24 },
  { cx: 46, cy: 27 },
  { cx: 46, cy: 28 },
  { cx: 46, cy: 29 },
  { cx: 47, cy: 6 },
  { cx: 47, cy: 7 },
  { cx: 47, cy: 8 },
  { cx: 47, cy: 9 },
  { cx: 47, cy: 10 },
  { cx: 47, cy: 11 },
  { cx: 47, cy: 12 },
  { cx: 47, cy: 13 },
  { cx: 47, cy: 14 },
  { cx: 47, cy: 15 },
  { cx: 47, cy: 16 },
  { cx: 47, cy: 18 },
  { cx: 47, cy: 19 },
  { cx: 47, cy: 22 },
  { cx: 47, cy: 23 },
  { cx: 47, cy: 26 },
  { cx: 47, cy: 27 },
  { cx: 47, cy: 28 },
  { cx: 47, cy: 29 },
  { cx: 48, cy: 6 },
  { cx: 48, cy: 7 },
  { cx: 48, cy: 8 },
  { cx: 48, cy: 9 },
  { cx: 48, cy: 10 },
  { cx: 48, cy: 11 },
  { cx: 48, cy: 12 },
  { cx: 48, cy: 13 },
  { cx: 48, cy: 14 },
  { cx: 48, cy: 15 },
  { cx: 48, cy: 16 },
  { cx: 48, cy: 17 },
  { cx: 48, cy: 22 },
  { cx: 48, cy: 26 },
  { cx: 48, cy: 27 },
  { cx: 48, cy: 28 },
  { cx: 49, cy: 7 },
  { cx: 49, cy: 8 },
  { cx: 49, cy: 9 },
  { cx: 49, cy: 10 },
  { cx: 49, cy: 11 },
  { cx: 49, cy: 12 },
  { cx: 49, cy: 13 },
  { cx: 49, cy: 14 },
  { cx: 49, cy: 15 },
  { cx: 49, cy: 25 },
  { cx: 49, cy: 26 },
  { cx: 49, cy: 27 },
  { cx: 49, cy: 28 },
  { cx: 50, cy: 6 },
  { cx: 50, cy: 7 },
  { cx: 50, cy: 8 },
  { cx: 50, cy: 9 },
  { cx: 50, cy: 10 },
  { cx: 50, cy: 11 },
  { cx: 50, cy: 17 },
  { cx: 50, cy: 25 },
  { cx: 50, cy: 26 },
  { cx: 50, cy: 27 },
  { cx: 50, cy: 28 },
  { cx: 50, cy: 29 },
  { cx: 50, cy: 30 },
  { cx: 51, cy: 6 },
  { cx: 51, cy: 7 },
  { cx: 51, cy: 8 },
  { cx: 51, cy: 9 },
  { cx: 51, cy: 10 },
  { cx: 51, cy: 11 },
  { cx: 51, cy: 15 },
  { cx: 51, cy: 16 },
  { cx: 51, cy: 22 },
  { cx: 51, cy: 26 },
  { cx: 51, cy: 27 },
  { cx: 51, cy: 28 },
  { cx: 51, cy: 29 },
  { cx: 51, cy: 30 },
  { cx: 52, cy: 7 },
  { cx: 52, cy: 8 },
  { cx: 52, cy: 9 },
  { cx: 52, cy: 10 },
  { cx: 52, cy: 12 },
  { cx: 52, cy: 13 },
  { cx: 52, cy: 23 },
  { cx: 52, cy: 24 },
  { cx: 52, cy: 25 },
  { cx: 52, cy: 26 },
  { cx: 52, cy: 27 },
  { cx: 52, cy: 28 },
  { cx: 52, cy: 29 },
  { cx: 53, cy: 8 },
  { cx: 53, cy: 9 },
  { cx: 53, cy: 10 },
  { cx: 53, cy: 11 },
  { cx: 53, cy: 12 },
  { cx: 53, cy: 23 },
  { cx: 53, cy: 26 },
  { cx: 53, cy: 27 },
  { cx: 53, cy: 28 },
  { cx: 54, cy: 8 },
  { cx: 54, cy: 9 },
  { cx: 54, cy: 10 },
  { cx: 55, cy: 8 },
  { cx: 55, cy: 9 },
  { cx: 55, cy: 10 },
  { cx: 56, cy: 29 },
  { cx: 55, cy: 30 },
  { cx: 55, cy: 31 },
]
