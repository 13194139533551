


























import { computed, defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import FiltersGroup from '@/components/searchtool/FiltersGroup.vue'

export default defineComponent({
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const filtersNames = computed(() => Object.keys(content.value.filters))

    return {
      filtersNames,
    }
  },
  components: {
    FiltersGroup,
  },
})
