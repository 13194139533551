







































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  props: {
    content: Object,
    active: {
      type: String,
      default: () => 'uitp-headquarters',
    },
    open: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: v => {
        console.log(v)
      },
    },
  },
  setup(props) {
    const isOpen = ref(props.open)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const changeActive = slug => {
      props.onSelect(slug)
    }

    const innerAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      changeActive,
      inner,
      innerAnims,
      isOpen,
      toggle,
      vBar,
    }
  },
})
