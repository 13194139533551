








/* eslint-disable quote-props */
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'

import { mapGetters } from '@/store/utils'
import { Pages } from '@/inc/types'
import { push, getContentPage, GtmMemberForm } from '@/inc/utils'

import Home from '@/views/Home.vue'
import About from './pages/About.vue'
import CaseStudies from './pages/CaseStudies.vue'
import Contact from './pages/Contact.vue'
import AcademyAbout from './pages/AcademyAbout.vue'
import AcademyPortfolio from './pages/AcademyPortfolio.vue'
import AcademyTrainers from './pages/AcademyTrainers.vue'
import DefaultTemplate from './pages/DefaultTemplate.vue'
import Events from './pages/Events.vue'
import Members from './pages/Members.vue'
import Membership from './pages/Membership.vue'
import MediaCenter from './pages/MediaCenter.vue'
import Partners from './pages/Partners.vue'
import Resources from './pages/Resources.vue'
import SearchTool from './pages/SearchTool.vue'
import ServicesPackages from './pages/ServicesPackages.vue'
import Storybook from './pages/Storybook.vue'
import Trainings from './pages/Trainings.vue'

/* eslint-disable quote-props */
const pages: Pages = {
  home: Home,
  about: About,
  'case-studies': CaseStudies,
  events: Events,
  contact: Contact,
  'academy-about': AcademyAbout,
  'academy-portfolio': AcademyPortfolio,
  'academy-trainers': AcademyTrainers,
  'default-template': DefaultTemplate,
  'media-center': MediaCenter,
  members: Members,
  membership: Membership,
  partners: Partners,
  resources: Resources,
  'services-packages': ServicesPackages,
  'search-tool': SearchTool,
  storybook: Storybook,
  trainings: Trainings,
}
/* eslint-enable quote-props */

export default defineComponent({
  name: 'page',
  setup(props, ctx) {
    const { content, template, tracking } = mapGetters(ctx, [
      'content',
      'template',
      'tracking',
    ])
    const slug = computed<string>(() => template.value)

    const component = computed<Vue>(() => pages[slug.value] || DefaultTemplate)

    const onLinkClick = e => {
      const h = e.currentTarget.href.toLowerCase()

      if (h.includes('application-form') && h.includes('pdf')) {
        const layer: GtmMemberForm = {
          event: 'lead-form',
          eventCategory: 'download-form',
          eventAction: 'click',
          eventLabel: 'become-member',
        }

        // DGTM 5.2.9
        push(layer)
      }
    }

    onMounted(() => {
      if (
        ctx.root.$router.$$type === 'client' &&
        ctx.root.$route.path.includes('become-a-member')
      ) {
        document.querySelectorAll('a').forEach(a => {
          a.addEventListener('click', onLinkClick)
        })
      }

      const gtmTemplate = tracking.value.template

      if (
        gtmTemplate === 'visibility-opportunities' ||
        gtmTemplate === 'community'
      ) {
        // DGTM 5.1.2
        push(
          getContentPage(gtmTemplate, {
            // eslint-disable-next-line quote-props
            title: content.value.hero.title,
            'content-topic': gtmTemplate,
            'content-type': content.value.hero.title.toLowerCase(),
            'content-public': 0,
            'content-lead': 1,
          })
        )
      }
    })

    onBeforeUnmount(() => {
      if (
        ctx.root.$router.$$type === 'client' &&
        ctx.root.$route.path.includes('become-a-member')
      ) {
        document.querySelectorAll('a').forEach(a => {
          a.removeEventListener('click', onLinkClick)
        })
      }
    })

    return {
      component,
      slug,
    }
  },
})
