
















import { computed, defineComponent } from '@vue/composition-api'
import { mapGetters, mapMutations } from '@/store/utils' // eslint-disable-line
import ResultsGrid from '@/components/searchtool/ResultsGrid.vue'

export default defineComponent({
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const results = computed(() => content.value.results)
    const hasNoResults = computed(() => {
      const r = results.value

      return r.events.length + r.medias.length + r.resources.length === 0
    })

    return {
      hasNoResults,
      results,
    }
  },
  components: {
    ResultsGrid,
  },
})
